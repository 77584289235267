<template>
    <div id="chart" class="justify-center">
        <apexchart type="bar" height="350" width="600" :options="chartOptions" :series="series"></apexchart>
    </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
export default {
    name: 'Responses',
    props: ['code'],
    components: {
          apexchart: VueApexCharts,
        },
        data() {
        return {
            respondents: {
                keys: [],
                values: []
            }
        };
    },
    mounted() {
        this.loadRespondents()
    },

    methods: {
         loadRespondents: function () {
            this.wait = true
            this.$http.get(`/api/profile/survey/${this.code}/respondents`).then(response => {
              console.log(response.data.data)
                // this.respondents.keys = response.data.data
                // this.respondents.values = this.respondents[0]
                // this.response = response.data.data.map(a => a.json)
            }).catch(error => {
                this.showError(error)
            }).then(() => {
                this.wait = false
            })
        }
    },
    computed: {
        series() {
            return [
                {
                    name: 'Project Status Stats',
                    data: this.respondents.values
                }
            ]
        },
        chartOptions() {
            return {
                chart: {
                    type: "bar",
                    height: 350,
                },
                theme: {
                    palette: 'palette2'
                },
                plotOptions: {
                    bar: {
                        borderRadius: 4,
                        columnWidth: '45%',
                        distributed: true,
                        horizontal: true,
                    },
                },
                dataLabels: {
                    enabled: true,
                },
                legend: {
                    show: false
                },
                xaxis: {
                    categories: this.respondents.keys
                },
            }
        }
    }
}
</script>
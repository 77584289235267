<template>
    <div class="wrapper container mt-5">
        <!-- Statistics -->
            <div class="row">

                 <div v-if="survey.published && respondents.length == 0" class="posts-empty">
                    <div class="posts-empty-inner">
                        <div class="posts-empty-inner_img">
                            <img loading="lazy" src="@/assets/img/empty-inbox.png" width="80" height="80" alt="Empty box"/>
                        </div>
                        <h4>Your survey has no responses.</h4>
                        <h6>Did you know you can <router-link class="font-weight-semibold" :to="`/profile/surveys/view/${this.code}/share`"><u>share</u></router-link> your survey to reach a wider audience?</h6>
                    </div>
                </div>

                <div v-else-if="!survey.published" class="posts-empty">
                    <div class="posts-empty-inner">
                        <div class="posts-empty-inner_img">
                            <img loading="lazy" src="@/assets/img/text-box.png" width="80" height="80" alt="Empty box"/>
                        </div>
                            <h4>Your survey has not been published.</h4>
                            <h6>Looks like your survey is still a draft. <router-link class="font-weight-semibold" :to="`/profile/surveys/view/${this.code}/questionnaire`">Continue editing</router-link></h6>
                    </div>
                </div>                  

                <div class="col-3">

                </div>
                <div class="col-9">
                    <div class="stat-card-item justify-center">
                        <ul class="stat-card">
                            <li v-for="(a, index) in respondent" :key="index"
                                class="list-group-item d-flex justify-content-between list-group-item-action align-items-center rounded info-card mb-5"  v-bind:style="styleObject">
                                <a href="#" class="active">
                                <span class="px-2">{{ a.question }} :</span>
                                    <div id="chart">
                                      <Responses></Responses>
                                    </div>
                                    <div class="choices_table">
                                        <table class="table table-sm table-bordered">
                                            <thead class="p-3">
                                            <tr>
                                                <th class="w-50">Choices</th>
                                                <th class="w-50">Responses</th>
                                            </tr>
                                            </thead>
                                            <tbody class="p-3">
                                            <tr>
                                                <td>{{ }}</td>
                                                <td>{{  }}</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </a> <br>
                            </li>
                        </ul>
                    </div>

                </div>
           </div>
    </div>
</template>

<script>
import moment from 'moment'
import Responses from '@/components/Views/Profile/Charts/Responses'
export default {
    name: 'SurveyStatistics',
    components: {Responses},
     props: ['code'],
    data() {
        return {
            respondents: [],
            respondent: null,
            response: [],
            isModalVisible: false,
            data: [],
            styleObject: {
                color: '#0B2559',
                fontSize: '13px',
                pointer: 'cursor'
            },
            error: ''
        }
    },
    mounted() {
        this.$http.get("/api/profile/survey/" + this.code).then(response => {
            this.survey = response.data.data
            this.loadRespondents()
        }).catch(error => {
            this.showError(error)
        })
    },
    methods: {
        loadRespondents: function () {
            this.wait = true
            this.$http.get(`/api/profile/survey/${this.code}/respondents`).then(response => {
                this.respondents = response.data.data
                this.respondent = this.respondents[0].json
                this.response = response.data.data.map(a => a.json)
            }).catch(error => {
                this.showError(error)
            }).then(() => {
                this.wait = false
            })
        },
        setRespondent(respondent) {
            this.respondent = respondent
        },
        callDate(date, dateType) {
            const date1 = new Date(date);
            if (dateType === "fullDate") {
                return moment(date1).format("Do MMMM YYYY");
            }
        },
        getStatistics() {
            this.statistics = true
            this.responses = false
        },
        showModal() {
            this.isModalVisible = true;
        },
        closeModal() {
            this.isModalVisible = false;
        },
        generateReport () {
            this.$refs.html2Pdf.generatePdf()
        }
    }
}
</script>

<style scoped>
/* .stat-card {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
} */
</style>